import { useEffect, useState } from 'react';
import './home.scss'

type LoadingType = {
    setIsLoading?(flag: boolean): void;
};
export default function Home({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 1) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <>
            <div className="home" id='home'>
                <div className="content">
                    <div className="wrapper" data-aos="fade-up">
                        <div className="logo">
                            <img src="/assets/logo-lg.png" alt="" />
                        </div>
                        <h1>MONKE</h1>
                        <h2>Reject Humanity <span>•</span> Return to Monke</h2>
                        {/* <h3>Receive 3% USDC reflections while you HODL</h3> */}
                        <a href="https://www.dextools.io/app/ether/pair-explorer/0xd1fca736d6bf9a8798e4ecb0609cf89dd73ec315" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">BUY NOW &amp; HODL</a>
                    </div>
                </div>
                <img src="/assets/banner.png" alt="" className="bg" onLoad={onLoad}/>
            </div>
            <div className="social_links_home">
                <a href="http://t.me/stealthlaunchser" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">Telegram</a>
                <span>•</span>
                <a href="https://twitter.com/themonkeorg" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">Twitter</a>
                <span>•</span>
                <a href="https://medium.com/@themonkeorg" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">Medium</a>
            </div>

        </>
    )
}