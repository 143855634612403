import { useEffect, useState } from 'react';
import './how_participate.scss'

type LoadingType = {
    setIsLoading?(flag: boolean): void;
};
export default function HowParticipate({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 1) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <>
            <div className="how_participate">
                <div className="content">
                    <div className="wrapper mb-3" data-aos="fade-up">
                        <div className="left">
                            <h5>3 EASY STEPS</h5>
                            <h2>How to Participate</h2>
                        </div>
                        <div className="right">
                            <a href="https://www.dextools.io/app/ether/pair-explorer/0xd1fca736d6bf9a8798e4ecb0609cf89dd73ec315" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">BUY NOW &amp; HODL</a>
                        </div>
                    </div>
                    <div className="wrapper" data-aos="fade-up">
                        <div className="col">
                            <h1>01</h1>
                            <h3>APE MONKE</h3>
                            <p>Swap ETH or any ERC20 token for MONKE using uniswap or similar.</p>
                        </div>
                        <div className="col">
                            <h1>02</h1>
                            <h3>SWAP APE COIN</h3>
                            <p>APE Coin is the perfect token to RETURN TO MONKE.</p>
                        </div>
                        <div className="col">
                            <h1>03</h1>
                            <h3>JOIN THE ARMY</h3>
                            <p>Community is everything. You must be engaged and do your part. MONKE is 100% decentralized and community driven. <span>YOU are the team!</span></p>
                        </div>
                    </div>
                    <div className="wrapper" data-aos="fade-up">
                        <div className="col w_full">
                            <h2><span>FLIP APE COIN MARKETCAP!</span></h2>
                            <h3>RETURN TO MONKE. ACHIEVE ENLIGHTENMENT</h3>
                            <p style={{marginBottom : 0}}>We’ve watched people make millions; and watched them gamble it away losing it all chasing hype narrative created by humans.</p>
                            <p> Chasing their human tendencies of greed nurtured by society and materialistic culture. What must we do?</p>
                            <ul>
                                <li>Return to Monke</li>
                                <li>Flip all projects that sell their product using idols</li>
                                <li>Buy more than people sell</li>
                                <li>Spread the news to humans so they can achieve enlightenment</li>
                                <li>Swap all shit coins, false idol coins, and dust into Monke</li>
                                <li>Become the Monke</li>
                            </ul>
                            <div className="btns">
                                <a href="https://www.dextools.io/app/ether/pair-explorer/0xd1fca736d6bf9a8798e4ecb0609cf89dd73ec315" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">BUY MONKE</a>
                                <a href="http://t.me/stealthlaunchser" target="_blank" rel="noreferrer" className="btn btn-lg btn-success">JOIN TELEGRAM</a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="/assets/ape-angle-left-bg.jpg" alt="" className="bg" onLoad={onLoad}/>
            </div>

        </>
    )
}