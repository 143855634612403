import { useEffect, useState } from 'react';
import './footer.scss'

type LoadingType = {
    setIsLoading?(flag: boolean): void;
};

export default function Footer({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 3) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="footer">
            <div className="footerContent" data-aos="fade-up">
                <div className="wrapper">
                    <p>
                        <img src="/assets/steps2.png" alt="" onLoad = {onLoad}/>
                        MONKE COMMUNITY
                        <img src="/assets/steps.png" alt=""  onLoad = {onLoad}/>
                    </p>
                    <img src="/assets/logo.png" alt="" className="logo" onLoad = {onLoad}/>
                    <div className='desc'>
                    My story doesn’t matter <span>•</span> Reject society <span>•</span> Return to Monke <span>•</span> Achieve enlightenment <span>•</span> Flip Ape coin this time
                    </div>
                    
               
                </div>
                <span>Copyright © 2022. All Rights Reserved.  </span>
            </div>
        </div>
    )
}
 