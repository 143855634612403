import { useEffect, useState } from 'react';
import './about.scss'

type LoadingType = {
    setIsLoading?(flag: boolean): void;
};
export default function About({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 1) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <>
            <div className="about" id = 'about'>
                <div className="content">
                    <div className="wrapper" data-aos="fade-up">
                        <div className="left">
                        </div>
                        <div className="right">
                            <h2>You are the Team</h2> 
                            <p>This is your token. We can flip all big money backed tokens. Open your mind. Return to MONKE.</p>

                            <h2>There are no Devs</h2>
                            <p>If you want to do something then do it. Dont look to other people. Make the memes. Make the tiktok videos. Make the youtube videos. Return to Monke. This is how we flip APE Coin</p>

                            <h2>Trust The Process</h2>
                            <p>Flipping APE Coin will take diamond hands, so we've added reflections in USDC so you can get rewarded while you open people's minds. There's a long road ahead.</p>

                            <h2>Reject Society and Idols - Return to Monke</h2>
                            <p>MONKE started with a mission to flip APE coin. It is really up to you to see what you can add. Work for your banana. Open your neighbor's mind and RETURN TO MONKE. THIS IS AN ARMY. It is new and small, but that also means we have the advantage. </p>
                        </div>
                    </div>
                </div>
                <img src="/assets/ape-angle-right-bg.jpg" alt="" className="bg" onLoad={onLoad}/>
            </div>
            

        </>
    )
}