import { useState } from 'react';
import { HashLink } from 'react-router-hash-link'
import './styles.scss';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Menu from 'components/menu/Menu';

const NavBar = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
        <div className={`navbar-container `} >
            <div className="header_content">
                <div className="wrapper">
                <div  className='logo'>
                    <HashLink to={'#home'} smooth>
                        <img src='/assets/logo.png' alt='' />
                        <h1>MONKE</h1>
                    </HashLink>
                </div>
                <ul className="nav_list">
                    <li><HashLink to={'#about'}  smooth>About</HashLink></li>
                    {/* <li><a href="https://rewards.returntomonke.io/" target="_blank" rel="noreferrer">VIEW REWARDS</a></li> */}
                    <li><a href="https://medium.com/@themonkeorg/monke-enlightenment-8faa8137d75" target="_blank" rel="noreferrer">WHITEPAPER</a></li>
                    {/* <li><a href="https://mint.returntomonke.io/" target="_blank" rel="noreferrer">NFT MINT</a></li> */}
                    <li>
                        {/* <div className="btn_div">
                            <ConnectButton />
                        </div> */}
                    </li>
                </ul>
                
                </div>
                
            </div>
            
            <button className={`showMenuBtn `} onClick={()=>setShowMenu(!showMenu)}>
                {!showMenu ? 
                    <i className="fas fa-bars"></i>:
                    <i className="fas fa-times"></i>
                }
            </button>
                    
        </div>
        <Menu setMenuOpen={setShowMenu} menuOpen={showMenu}/>
        </>
    )
}
export default NavBar;