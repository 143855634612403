import { useEffect, useState } from 'react';
import Footer from 'components/footer/Footer';
import Home from 'components/sections/Home/Home';
import About from 'components/sections/About/About';
import HowParticipate from 'components/sections/HowParticipate/HowParticipate';
import Tokenomics from 'components/sections/Tokenomics/Tokenomics';
import HowBuy from 'components/sections/HowBuy/HowBuy';
import QuickStart from 'components/sections/QuickStart/QuickStart';
import Loading from 'components/loading/Loading';
import CustomModal from 'components/Modal/CustomModal';
import CheckBox from 'components/Widgets/CheckBox';

const HomePage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoading3, setIsLoading3] = useState(true);
    const [isLoading4, setIsLoading4] = useState(true);
    useEffect(() => {


        if (!isLoading1 && !isLoading2 && !isLoading3 && !isLoading4 ){
            setIsLoading(false)
        }else{
            setIsLoading(true)
        }

    }, [isLoading1, isLoading2, isLoading3, isLoading4]);

    const [isAccept, setIsAccept] = useState(false);
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [showPreference, setShowPreference] = useState(false);
    
    const [isCheckedEssential, setIsCheckedEssential] = useState(false);
    const [isCheckedPerformance, setIsCheckedPerformance] = useState(false);
    const [isCheckedAnalytics, setIsCheckedAnalytics] = useState(false);
    const [isCheckedAdvertising, setIsCheckedAdvertising] = useState(false);
    const [isCheckedSocial, setIsCheckedSocial] = useState(false);
    const [isCheckedUnclasified, setIsCheckedUnclasified] = useState(false);

    const onDecline = ()=>{
        setIsAccept(true)
    }

    const onAccept = ()=>{
        setIsAccept(true)
    }
    return (
        <>
        <Loading isLoading = {isLoading}/>
        <div className="sections">
            <Home setIsLoading={setIsLoading1}/>
            <About setIsLoading={setIsLoading2}/>
            <HowParticipate setIsLoading={setIsLoading3}/>
            <Tokenomics/>
            <HowBuy/>
            <QuickStart/>
            <Footer setIsLoading={setIsLoading4}/>
            {!isAccept && 
                <div className="notification_div">
                    <div className="content">
                        <div className="left">
                            <a href="https://termly.io/products/cookie-consent-manager/" target="_blank" rel="noreferrer"><img src="/assets/download.svg" alt="" /></a>
                            <p>We use essential cookies to make our site work. With your consent, we may also use non-essential cookies to improve user experience and analyze website traffic. By clicking “Accept,“ you agree to our website's cookie use as described in our <span onClick={()=>setShowCookieModal(true)}>Cookie Policy.</span> You can change your cookie settings at any time by clicking <span onClick={()=>setShowPreference(true)}>“Preferences.”</span></p>
                        </div>
                        <div className="right">
                            <button className='outline' onClick={()=>setShowPreference(true)}>Preferences</button>
                            <button onClick={onDecline}>Decline</button>
                            <button onClick={onAccept}>Accept</button>
                        </div>
                    </div>
                </div>
            }
        </div>
        <CustomModal 
            showModal = {showCookieModal} 
            setShowModal={setShowCookieModal}
            children={
                <>
                <div className="my_modal">
                    <div className="content">
                    <iframe title="iframe" className="termly-style-iframe-a9b1d5" frameBorder={0} src="https://app.termly.io/document/cookie-policy/d5cf3239-4f5e-4e5d-b207-bd0012c7d02f" width="100%" id="iFrameResizer1" scrolling="no" ></iframe>
                    </div>
                </div>
                </>
            }
        />

        <CustomModal 
            showModal = {showPreference} 
            setShowModal={setShowPreference}
            children={
                <>
                <div className="my_modal_1">
                    <div className="content">
                        <div className="row">
                            <h3>Your Cookie Preferences</h3>
                            <p>We use different types of cookies to optimize your experience on our website. Click on the categories below to learn more about their purposes. You may choose which types of cookies to allow and can change your preferences at any time. Remember that disabling cookies may affect your experience on the website. You can learn more about how we use cookies by visiting our <span  className='detail_btn'>Cookie Policy.</span></p>
                        </div>
                        <ul>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Essential Cookie (1)' checked = {isCheckedEssential} onChange={setIsCheckedEssential}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These cookies are necessary to the core functionality of our website and some of its features, such as access to secure areas.</p>

                            </li>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Performance and Functionality Cookies (0)'checked = {isCheckedPerformance} onChange={setIsCheckedPerformance}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These cookies are used to enhance the performance and functionality of our websites but are nonessential to their use. However, without these cookies, certain functionality (like videos) may become unavailable.</p>

                            </li>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Advertising and Customization Cookies (0)'checked = {isCheckedAdvertising} onChange={setIsCheckedAdvertising}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalized site experience for you.</p>

                            </li>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Advertising Cookies (0)'checked = {isCheckedAdvertising} onChange={setIsCheckedAdvertising}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These cookies are used to make advertising messages more relevant to you. They prevent the same ad from continuously reappearing, ensure that ads are properly displayed for advertisers, and in some cases select advertisements that are based on your interests.</p>

                            </li>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Social networking Cookies (0)'checked = {isCheckedSocial} onChange={setIsCheckedSocial}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These cookies enable you to share our website's content through third-party social networks and other websites. These cookies may also be used for advertising purposes.</p>

                            </li>
                            <li>
                                <div className="check_div">
                                <CheckBox label='Unclassified Cookies (0)'checked = {isCheckedUnclasified} onChange={setIsCheckedUnclasified}/>
                                <span className='detail_btn'>Details</span>
                                </div>
                                <p>These are cookies that have not yet been categorized. We are in the process of classifying these cookies with the help of their providers.</p>
                            </li>
                            <li>
                                <div className="btns">
                                    <button>
                                    { (isCheckedEssential || isCheckedPerformance 
                                    || isCheckedAnalytics || isCheckedAdvertising || isCheckedSocial || isCheckedUnclasified )?
                                        'Discard Changes' : 'Decline All'}
                                    </button>
                                    <button>
                                    { (isCheckedEssential || isCheckedPerformance 
                                    || isCheckedAnalytics || isCheckedAdvertising || isCheckedSocial || isCheckedUnclasified )?
                                        'Save and Finish' : 'Allow All'}
                                        </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                </>
            }
        />
        </>
        
    )
}

export default HomePage;