import './tokenomics.scss'
export default function Tokenomics() {

    return (
        <>
            <div className="tokenomics">
                <div className="content">
                    <div className="wrapper mb-3" data-aos="fade-up">
                        <h2>TOKENOMICS</h2>
                        <ul>
                            <li>
                                <p>Liquidity</p>
                                <h3>0%</h3>

                            </li>
                            <li>
                                <p>Reflections</p>
                                <h3>0% USDC</h3>

                            </li>
                            <li>
                                <p>Team</p>
                                <h3>0%</h3>
                            </li>
                            <li>
                                <p>Pre Sale</p>
                                <h3>0%</h3>
                            </li>
                            <li>
                                <p>Marketing</p>
                                <h3>0%</h3>
                            </li>
                            <li>
                                <p>Public</p>
                                <h3>100%</h3>
                            </li>
                            
                        </ul>
                    </div>
                    
                </div>
            </div>

        </>
    )
}