import './quick_start.scss'

const data =[
    {
        title : 'Create MetaMask wallet',
        text : 'Create a MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive your MONKE tokens'
    },
    {
        title : 'Send ETH to your wallet',
        text : 'You can buy Ethereum (ETH) directly on MetaMask or transfer it to your MetaMask Wallet from exchanges like Bitmart, Ethereum, etc'
    },
    {
        title : 'Connect your wallet to UniSwap',
        text : 'Connect your wallet to UniSwap by clicking ‘Connect wallet’ and selecting MetaMask'
    },
    {
        title : 'Swap ETH for MONKE',
        text : 'You can start swapping as soon as you have ETH available! Press ‘Select a token’ and enter the token address or search for it on the tokens list.'
    }
]

export default function QuickStart() {
    
    return (
        <>
            <div className="quick_start" >
                <div className="content">
                    <div className="wrapper" data-aos="fade-up">
                        <h2>Quick Start Guide</h2>
                        <ul>
                            {data.map((d, k)=>(
                                <li key={k} className={k < data.length -1 ? 'border_bottom':''}>
                                <div className="number">{k + 1}</div>
                                <div className="line"></div>
                                <div className="text">
                                    <h3>{d.title}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            

        </>
    )
}