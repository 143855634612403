import './how_buy.scss'

export default function HowBuy() {

    return (
        <>
            <div className="how_buy">
                <div className="content">
                    <div className="wrapper mb-3" data-aos="fade-up">
                        <div className="left">
                            <h2>How to Buy</h2>
                            <p>MONKE currently runs only on the Ethereum Network, which means it can be best purchased and sold through <span>UniSwap</span></p>
                            <p>MONKE is a decentralized experiment project. As such we always recommend the use of DEXs. If you choose to utilize a CEX instead, remember to research it first to ensure it is both safe and secure.</p>
                        </div>
                        <div className="right">
                            <iframe height="660px" width="100%"data-categories="unclassified" src="https://www.flooz.trade/embedded/0x920e4Cc5c50548ED2825de9e380A4d7b2A7Ff143/?backgroundColor=142c24&amp;chainId=1"></iframe>
                        </div>
                    </div>
                    
                </div>
            </div>

        </>
    )
}