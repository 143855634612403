import "./menu.scss"
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { HashLink } from 'react-router-hash-link'
type MenuType = {
    menuOpen: boolean;
    setMenuOpen(flag: boolean): void;
};

export default function Menu({ menuOpen, setMenuOpen }: MenuType) {
    

    return (
        <div className={`sidebar ${menuOpen ? "active":''} `}>
            <div className="menu_list" id='menuExp'>
                <HashLink to={'#about'}  onClick={()=>setMenuOpen(false)}>
                About
                </HashLink>
                {/* <a href="https://rewards.returntomonke.io/" target="_blank" rel="noreferrer"onClick={()=>setMenuOpen(false)}>
                    VIEW REWARDS
                </a> */}
                <a href="https://medium.com/@themonkeorg/monke-enlightenment-8faa8137d75" target="_blank" rel="noreferrer"onClick={()=>setMenuOpen(false)}>WHITEPAPER</a>
                {/* <a href="https://mint.returntomonke.io/" target="_blank" rel="noreferrer"onClick={()=>setMenuOpen(false)}>NFT MINT</a> */}
                {/* <div className="btn_div">
                    <ConnectButton />
                </div> */}
                
            </div>
        </div>
    )
}

